import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
//import { Chatbot } from '@aspendental/design-system'
import { version } from './package.json'
import './src/styles/global.css'

if (process.env.GATSBY_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    releaseStage: process.env.GATSBY_DEPLOY_ENVIRONMENT || 'development',
    appVersion: version,
    plugins: [new BugsnagPluginReact()],
    endpoints: {
      notify: 'https://bugsnag-notify.aspendental.com',
      sessions: 'https://bugsnag-sessions.aspendental.com',
    },
  })
}

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

/**
 * This function is used to wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * @param {ReactNode} element The “Page” React Element built by Gatsby
 */

export const wrapRootElement = ({ element }) => {
  // TODO: initialize contexts and client-side DB here
  //  https://aspendent.atlassian.net/wiki/spaces/TECH/pages/2337931318/Application+State+-+Standards+-+Web#Application-State
  return (
    <ErrorBoundary>
      {element}
      {/* https://aspendental.amelia.com/Amelia/ui/aspen/?embed=iframe&domainCode=aspencallcenter */}
      {/* {process.env.GATSBY_CHATBOT_URL && <Chatbot url={process.env.GATSBY_CHATBOT_URL} />} */}
    </ErrorBoundary>
  )
}
